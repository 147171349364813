import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { appModule } from './app';
import { researchModule } from './research';
import { categoryLabModule } from './categoryLab';
import { actionLogModule } from './actionLog';
import { insightsModule } from './insights';
import { copilotModule } from './copilot';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    app: appModule,
    research: researchModule,
    categorylab: categoryLabModule,
    actionlog: actionLogModule,
    insights: insightsModule,
    copilot: copilotModule
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
