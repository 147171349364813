import { ICoPilotState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    searchCoPilotConversations: (state: ICoPilotState) => state.conversations,
    searchCoPilotQuickChatShow: (state: ICoPilotState) => state.quickChatShow,
    searchCoPilotQuickChatTemplateToApply: (state: ICoPilotState) => state.quickChatTemplateToApply,
    searchCoPilotConditionCheckToggle: (state: ICoPilotState) => state.conditionCheckToggle,
};

const {read} = getStoreAccessors<ICoPilotState, State>('');

export const readCoPilotConversations = read(getters.searchCoPilotConversations);
export const readCoPilotQuickChatShow = read(getters.searchCoPilotQuickChatShow);
export const readCoPilotQuickChatTemplateToApply = read(getters.searchCoPilotQuickChatTemplateToApply);
export const readCoPilotConditionCheckToggle = read(getters.searchCoPilotConditionCheckToggle);
