import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import DOMPurify from 'dompurify';

// API
import { api } from '@/api';
import { apiCoPilot } from '@/api-copilot';
import { dispatchShowStandardErrorToast } from '../app/actions';
import { ICoPilotState } from './state';
import { ICoPilotChatConversation, ICoPilotTemplate } from '@/interfaces/copilot';


type CoPilotContext = ActionContext<ICoPilotState, State>;


export const actions = {
    async addConversation(context: CoPilotContext, payload: {conversation: ICoPilotChatConversation}) {
        try {
            context.state.conversations.push(payload.conversation);
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async deleteConversation(context: CoPilotContext, payload: {conversationID: string}) {
        try {
            context.state.conversations = context.state.conversations.filter((conv) => conv._id !== payload.conversationID);
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async sendMessage(context: CoPilotContext, payload: {conversationID: string, message: string}) {
        try {
            let conversation = context.state.conversations.find(e => e._id == payload.conversationID);
            if (conversation) {
                conversation.messages.push({ text: payload.message, textOnlyText: payload.message, isUser: true, loading: false });
                try {
                    conversation.messages.push({ text: '', textOnlyText: '', isUser: false, loading: true });
                    const response = await apiCoPilot.copilotChat(
                        context.rootState.main.token,
                        payload.conversationID,
                        payload.message
                    );
                    let answer = response.data;
                    answer = DOMPurify.sanitize(answer);
                    let answerOnlyText = answer.replace(/<[^>]*>?/gm, '');
                    answer = answer.trim();
                    conversation.messages[conversation.messages.length-1].text = answer;
                    conversation.messages[conversation.messages.length-1].textOnlyText = answerOnlyText;
                    conversation.messages[conversation.messages.length-1].loading = false;
                } catch (error) {
                    console.error('Error sending message:', error);
                    conversation.messages[conversation.messages.length-1].text = 'An error occurred while sending your message.';
                    conversation.messages[conversation.messages.length-1].loading = false;
                }
            }
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },

    async changeQuickChatShow(context: CoPilotContext, payload: {show: boolean}) {
        try {
            context.state.quickChatShow = payload.show;
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async changeQuickChatShowToggle(context: CoPilotContext) {
        try {
            context.state.quickChatShow = !context.state.quickChatShow;
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async changeQuickChatTemplateToApply(context: CoPilotContext, payload: {templateToApply: ICoPilotTemplate | null}) {
        try {
            context.state.quickChatTemplateToApply = payload.templateToApply;
        } catch (err) {
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async changeConditionCheckToggle(context: CoPilotContext) {
        context.state.conditionCheckToggle = !context.state.conditionCheckToggle;
    },
};

const { dispatch } = getStoreAccessors<ICoPilotState | any, State>('');

export const dispatchCoPilotSendMessage = dispatch(actions.sendMessage);
export const dispatchCoPilotAddConversation = dispatch(actions.addConversation);
export const dispatchCoPilotDeleteConversation = dispatch(actions.deleteConversation);
export const dispatchCoPilotChangeQuickChatShow = dispatch(actions.changeQuickChatShow);
export const dispatchCoPilotChangeQuickChatShowToggle = dispatch(actions.changeQuickChatShowToggle);
export const dispatchCoPilotChangeQuickChatTemplateToApply = dispatch(actions.changeQuickChatTemplateToApply);
export const dispatchCoPilotChangeCondictionCheckToggle = dispatch(actions.changeConditionCheckToggle);
