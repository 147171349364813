import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ICoPilotState } from './state';


export const mutations = {
    //setInsights(state: IInsightsState, payload: IInsightsData[]) {
    //    state.insightsData = payload;
    //},
};

const {commit} = getStoreAccessors<ICoPilotState | any, State>('');

//export const commitSetInsightsData = commit(mutations.setInsights);

