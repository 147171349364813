import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ICoPilotState } from './state';

const defaultCoPilotState: ICoPilotState = {
    quickChatShow: false,
    quickChatTemplateToApply: null,
    conditionCheckToggle: false,
    conversations: [],
};

export const copilotModule = {
    name: 'copilot',
    namespaced: false,
    state: defaultCoPilotState,
    getters,
    mutations,
    actions,
};
