// OTHER
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '@/env';

function authHeaders(token: string): AxiosRequestConfig {
  if (process.env.NODE_ENV === 'development') {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        client: 'test',
      },
    };
  } else {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
}


export const apiCoPilot = {
  async copilotChat(
    token: string,
    conv_id: string,
    message: string,
  ) {
    let url: string = `${apiUrl}/api/v1/llm/copilot/${conv_id}`;
    url = `${url}?question=${message}`;
    return axios.get<string>(url, authHeaders(token));
  },
  async copilotDeleteChat(
    token: string,
    conv_id: string,
  ) {
    let url: string = `${apiUrl}/api/v1/llm/copilot/${conv_id}`;
    return axios.delete(url, authHeaders(token));
  },
}
